import ApiService from "@/services/ApiService";

export const TOKEN_KEY = 'access_token'
export const AUTH_DOMAIN = 'https://auth.vebotv.me'
// export  const AUTH_DOMAIN = 'http://vebo.local:92'

export default class UserService {
    static getToken(){
        return localStorage.getItem(TOKEN_KEY)
    }
    static saveToken(access_token){
        localStorage.setItem(TOKEN_KEY, access_token)
    }
    static removeToken(){
        localStorage.removeItem(TOKEN_KEY)
    }
    static async login(email, password){
        const response = await ApiService.post(`${AUTH_DOMAIN}/v1/auth/login`,{
            email, password
        })
        if(response.data.status === 1){
            const {
                user, access_token
            } = response.data.data

            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }
        }else {
            throw new Error(response.data.data)

        }

    }
    static async logout(){
        try {
            this.removeToken()
        }catch (e) {
            //
        }
        try{
            await ApiService.get(`${AUTH_DOMAIN}/v1/auth/logout`)
        }catch (e) {
            //
        }
        localStorage.removeItem(TOKEN_KEY)
        return true
    }
    static async fetchUserFromToken(){
        const response = await ApiService.get(`${AUTH_DOMAIN}/v1/user/profile`)
        if(response.data.status === 1){
            const {
                user, access_token
            } = response.data.data

            return {
                user, access_token
            }
        }
        return {
            user: null, access_token: null
        }
    }
    static async fetchUserFromCookie(){
        const response = await ApiService.get(`${AUTH_DOMAIN}/v1/user/profile-cookie`)
        if(response.data.status === 1){
            const {
                user, access_token
            } = response.data.data

            if(access_token){
                this.saveToken(access_token)
            }
            return {
                user, access_token
            }
        }
        return {
            user: null, access_token: null
        }
    }
    static async fetchUser(){
        if(this.getToken()){
            return this.fetchUserFromToken()
        }else {
            return this.fetchUserFromCookie()
        }
    }
    static async updateInfo(data){
        try{
            const response = await ApiService.post(`${AUTH_DOMAIN}/v1/user/profile`,data)
            return response.data
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }

    static async changePassword(password, new_password){
        try{
            const response = await ApiService.post(`${AUTH_DOMAIN}/v1/user/change-password`, {
                password,
                new_password
            })
            return response.data
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }
    static async uploadAvatar(form){
        try{
            const response = await ApiService.vueInstance.axios.post(`${AUTH_DOMAIN}/upload`, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            return {
                status : 1,
                data: response.data.data
            }
        }catch (e) {
            return {
                status : 0,
                message: e.message
            }
        }
    }


}