<template>
  <div class="mc_live_wrap">
    <div class="mcf-btn" @click="playerFull"><i class="fas fa-expand"></i></div>
    <div id="live_full" class="mc_live">
      <div class="mc_l-left">
        <div class="mcb_-linksz">
          <a :href="getBtnAdsBetLive('live_match_top')" target="_blank" class="sv-link btn-bet-top">Đặt cược UY TÍN</a>
        </div>
        <div class="mc_top w-social" style="height:auto">
          <div class="mcb_-links">

            <a style="margin-bottom:3px" href="javascript:void(0)" @click="changeLink('mp')" class="sv-link" :class="{active : activeLink === 'mp'}" v-if="item.has_tracker"><i class="fas fa-caret-right mr-2"></i>Mô Phỏng</a>
            <template v-for="(link, index) in play_urls" :key="index">
              <a v-if="link.cdn != 'tvstation'" style="margin-bottom:3px" href="javascript:void(0)" @click="changeLink(`${index + 1}`)" class="sv-link" :class="{active : `${index + 1}` === activeLink}"><i class="fas fa-caret-right mr-2"></i>{{ link.name }}</a>
              <a v-else style="margin-bottom:3px" :href="'/goto?url=' + encodeURIComponent(link.url)" target="_blank" rel="nofollow" class="sv-link"><i class="fas fa-caret-right mr-2"></i>Nhà đài</a>
            </template>
            <div class="clearfix"></div>
          </div>
          <!--<div class="social-block">
             <div class="sb-text">Chia sẻ</div>
             <div class="addthis_inline_share_toolbox"></div>
             <div class="clearfix"></div>
           </div>-->
          <div class="clearfix"></div>
        </div>
        <div id="vbplayer" class="vb-player">
          <div id="jw-container" v-show="show_frame_player">
            <iframe v-if="embed_mode" :src="embed_url" width="100%" height="100%"
                    allowtransparency="yes" allow="autoplay" frameborder="0" marginheight="0" marginwidth="0"
                    allowfullscreen
                    scrolling="auto"></iframe>
          </div>
          <div v-if="item.has_tracker" v-show="activeLink==='mp'">
            <MatchLiveTracker v-if="show_frame_mp" :tracker_url="item.live_tracker"></MatchLiveTracker>
          </div>
          <div v-else v-show="activeLink==='mp'">
            <MatchCountDown :timestamp="item.timestamp"></MatchCountDown>
          </div>
        </div>
        <div class="mc_bottom">
          <div class="mct_-blv" v-if="commentators && commentators.length > 0  &&  commentators.length <= 2">
            <div class="bbox" v-for="(caster, index) in commentators" :key="index" :class="{
                'blv-1' : index === 0,
                'blv-2' : index === 1,
              }">
              <div class="user-avatar"><img loading="lazy" :src="caster.avatar" :alt="caster.name" :title="caster.name"></div>
              <div class="mct__-info">
                <div class="title">Bình luận viên</div>
                <div class="name">{{ caster.name }}</div>
              </div>
              <div class="mct__-icon"><i class="fas fa-microphone"></i></div>
              <div class="clearfix"></div>
            </div>
          </div>
          <template v-else-if="commentators && commentators.length > 2">
            <div class="mct_-blv multi" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="user-avatar" v-for="(caster, index) in commentators" :key="index">
                <img loading="lazy" :src="caster.avatar" :alt="caster.name" :title="caster.name">
              </div>
              <div class="mct__-multiinfo">{{ commentators.length }} BLV <i class="fas fa-angle-down ml-2"></i></div>
              <div class="mct__-icon"><i class="fas fa-microphone"></i></div>
              <div class="clearfix"></div>
            </div>
            <div class="dropdown-menu dropdown-menu-model dropdown-blv">
              <div class="dropdown-item" v-for="(caster, index) in commentators" :key="index">
                <div class="user-avatar"><img loading="lazy" :src="caster.avatar" :alt="caster.name" :title="caster.name"></div>
                <div class="blv-name">{{ caster.name }}</div>
              </div>
            </div>
          </template>
          <div class="mct_-bet">
            <a :href="getBtnAdsBetLive('live_match')" target="_blank" rel="nofollow" class="item item-betnow">
              <i class="fas fa-futbol"></i>
              <span>Đặt cược</span> trận này
            </a>
            <a href="#" class="item item-mobile">
              <i class="fas fa-mobile"></i>
              <span>Trực tiếp</span> trên đ.thoại
            </a>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <div id="count-height" class="mc_l-right m-height">
        <div class="vb-button">
          <div class="mct_-bet">
            <a :href="getBtnAdsBetLive('soikeo')" target="_blank" rel="noopener nofollow" class="item item-soikeo">
              <i class="fas fa-search"></i>
              <span>Soi kèo</span> trận này
            </a>
            <a :href="getBtnAdsBetLive('tyle')" target="_blank" rel="noopener nofollow" class="item item-tyle">
              <i class="fas fa-percentage"></i>
              <span>Tỷ lệ</span> trận này
            </a>
          </div>
          <div class="clearfix"></div>
        </div>
        <div class="vb-chatbox">
          <div id="toggle-chat" class="close-chat" @click="showChat"></div>
          <div class="chatbox_wrap">
            <div class="chatbox_hide" @click="showChat">
              <div class="chatbox_hide_before"></div>
              <button class="btn for-desktop"><i class="fas fa-comments mr-2"></i>Xem bình luận</button>
              <button id="goFS" ref="goFS" @click="requestFullscreen" class="btn for-mobile"><i
                  class="fas fa-comments mr-2"></i>Xem bình luận
              </button>
            </div>
<!--            <div class="require-login" v-if="chat_require_login && !isLoggedIn">
              <a data-toggle="modal" data-target="#modallogin" id="cb-login"><strong>Đăng nhập</strong><span>để tham gia bình luận</span></a>
            </div>-->
            <iframe v-if="show_frame_chat" loading="lazy" :src="getChat()" width="100%" height="450"
                    allowtransparency="yes" allow="autoplay" frameborder="0" marginheight="0" marginwidth="0"
                    scrolling="auto"></iframe>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>
<script>

import * as $ from 'jquery'
import {useRoute} from "vue-router";
import MatchLiveTracker from "@/components/match/player/MatchLiveTracker";
import MatchCountDown from "@/components/match/player/MatchCountDown";
import {getCurrentInstance, ref} from "vue";
import ApiService from "@/services/ApiService";
import {stringify} from "query-string";
import {mapGetters, mapState} from "vuex";
export default {
  name: "MatchPlayer",
  components: {
    MatchLiveTracker,
    MatchCountDown,
  },
  data() {
    return {
      show_frame_chat  : false,
      show_frame_mp    : false,
      show_frame_player: false,
    }
  },
  mounted() {
    //jwplayer.key = '3SYLbRo6MN5cBDxwpZh3dl1gb0lMTUOos31M5hoAlf4='
    this.fetchMeta().then(() => {
      const link = this.route.query.link || this.defaultLink()
      this.changeLink(link, false)
    })

    const component = this
    setInterval(function () {
      component.fetchMeta()
    }, 2 * 60 * 1000)

    if (window.enable_chat === true) {
      this.showChat()
    }
  },
  setup() {

    const app      = getCurrentInstance()
    const theme_id = app.appContext.config.globalProperties.$siteId

    let enable_odds        = false
    let chat_require_login = false
    if (window.enable_odds === true) {
      enable_odds = true
    }
    if (window.chat_require_login === true) {
      chat_require_login = true
    }
    const embed_url = ref("")
    let embed_mode  = false
    if (window.embed_mode && window.embed_mode === true) {
      embed_mode = true
    }
    let base_embed_url = ""
    if (window.base_embed_url) {
      base_embed_url = window.base_embed_url
    }

    const route        = useRoute();
    const modelId      = route.params.id
    const activeLink   = ref(null)
    activeLink.value   = route.query.link || null
    const play_urls    = ref(null)
    const commentators = ref(null)
    const fetchMeta    = async () => {
      const response = await ApiService.get(`/api/match/${modelId}/meta`)
      if (response.data.status === 1) {
        play_urls.value    = response.data.data.play_urls || null
        commentators.value = response.data.data.commentators || null
      }
    }

    return {
      base_embed_url,
      embed_url,
      embed_mode,
      chat_require_login,
      enable_odds,
      theme_id,
      route,
      activeLink,
      play_urls,
      commentators,
      fetchMeta,
      slug   : route.params.slug,
      modelId: route.params.id,
    }
  },
  computed: {
    item() {
      return this.$store.state.match.matches["id_" + this.modelId];
    },
    ...mapState({
      player      : state => state.match.player,
      peerInstance: state => state.match.peerInstance,
    }),
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
      //chat_url: "auth/chatUrl"
    }),
  },
  methods: {
    defaultLink() {
      if (this.play_urls && this.play_urls.length > 0) {
        return '1'
      }
      return 'mp'
    },
    changeLink(link, updateUrl = true) {
      if (this.player) {
        if (this.peerInstance) {
          try {
            this.peerInstance.detach(this.player)
          } catch (e) {/**/
          }
        }
      }
      this.$store.commit('match/setPlayer', null)
      this.$store.commit('match/setPeerInstance', null)

      this.activeLink = link
      if (link === 'mp') {
        if (this.show_frame_player) {
          this.show_frame_player = false
        }
        if (!this.show_frame_mp) {
          this.show_frame_mp = true
        }
      } else {
        const videoIndex = parseInt(link)
        if (videoIndex > this.play_urls.length) {
          return this.changeLink(this.defaultLink(), true)
        }
        const videoUrl = this.play_urls[videoIndex - 1].url
        this.setupPlayer(videoUrl)
      }
      if (updateUrl) {
        const query   = {
          ...this.route.query,
          link
        }
        const new_url = "?" + stringify(query)
        history.pushState({}, null, new_url);
      }
    },
    /*initOdds() {
      const oddsWrapper = '#worldcup-theme';
      let intervalAdd   = setInterval(() => {
        if ($(`${oddsWrapper}`).length != 0 || $('#jw-container').length == 0) {
          clearInterval(intervalAdd);
          return;
        } else {
          $('.jw-media.jw-reset').append(`
                <div id="worldcup-theme" class="position-absolute d-block vb-bet"></div>
            `)
          if (window.odds_run) {
            window.odds_run()
          }
        }
      }, 1000);

      setInterval(() => {
        if ($('#jw-container .banner-bottom').length == 0) {
          $('#jw-container').append('<div style="width:100%;position:absolute;bottom:0;" class="banner-bottom"><a rel="nofollow" href="https://8xbet9.com" target="_blank"><img src="https://i.imgur.com/XiYAhRS.gif"  class="" style="width:100%"></a></div>');
        }
        if ($('#jw-container .banner-bottom').length !== 0) {
          var bannerH = $('#jw-container .banner-bottom').outerHeight() || 0;
          var width   = $(document).width() > 540 ? 20 : 10;
          $('.jw-controlbar.jw-reset').css('bottom', bannerH + width);
        }
      }, 1000);
    },*/
    buildStreamID(url) {
      const urlParser = new URL(url)
      return `${this.modelId}_${urlParser.pathname}`
    },
    buildEmbedUrl(url, type) {
      return `${this.base_embed_url}?link=${encodeURIComponent(url)}&id=${this.modelId}&theme_id=${this.theme_id}&t=${type}`
    },
    handlePlayEmbed(url, type) {
      this.show_frame_player = true
      this.embed_url         = this.buildEmbedUrl(url, type)
    },
    setupPlayer(videoUrl) {
      const t = this.item.sport_type == 'basketball' ? 2 : 1;
      if (this.embed_mode === true) {
        this.handlePlayEmbed(videoUrl, t)
        return
      }
      this.show_frame_player = true

      // eslint-disable-next-line no-undef
      /*const player           = jwplayer("jw-container");
      const autostart        = true;
      let preroll            = "https://vebo-vast.pages.dev/vast-30s-ad.xml?v=1.0"
      let logo               = {
        file    : "https://vebo-vast.pages.dev/f88-logo.gif?v=1.0",
        hide    : false,
        link    : "https://www.fb88affok.com/CreativeBanner/Track?aID=5456&cID=1060&tID=2272",
        margin  : "10",
        position: "bottom-right",
      }
      if (this.theme_id === "xoilac") {
        preroll = "https://vebo-vast.pages.dev/xl/vast-30s-ad.xml?v=1.0"
        logo    = {
          file    : "https://i.imgur.com/8voRVx2.gif",
          hide    : false,
          link    : "https://v9betasia.com?affcode=027390&utm_source=XoilactvA2&utm_medium=BrandLogoInVideo&utm_campaign=Media_Streaming_20221001&utm_id=v9streaming20221001&utm_content=BrandLogoInVideo",
          margin  : "10",
          position: "bottom-right",
        }
      }
      player.setup({
        title          : `${this.item.home.name} - ${this.item.away.name}`,
        sources        : [{file: videoUrl}],
        swarmId        : `${this.item.getId()}` + videoUrl,
        cast           : {},
        autostart      : autostart,
        preload        : "auto",
        sharing        : {
          "sites": ["reddit", "facebook", "twitter"]
        },
        width          : "100%",
        height         : "100%",
        primary        : "html5",
        withCredentials: false,
        advertising    : {
          withCredentials : false,
          admessage       : "Quảng cáo sẽ tự động đóng sau xx giây",
          autoplayadsmuted: false,
          client          : "vast",
          cuetext         : "Quảng cáo",
          preloadAds      : true,
          rules           : {
            startOnSeek   : "none",
            timeBetweenAds: 60,
          },
          schedule        : [
            {
              offset    : "pre",
              skipoffset: 1,
              tag       : [
                preroll,
              ],
              type      : "linear",
            },
            {
              offset    : 10,
              skipoffset: 30,
              tag       : [
                "https://vebo-vast.pages.dev/overlay2.xml?v=1.0",
              ],
              type      : "nonlinear",
            },
            {
              offset    : "00:03:00",
              skipoffset: 30,
              tag       : [
                "https://vebo-vast.pages.dev/overlay2.xml?v=1.0",
              ],
              type      : "nonlinear",
            },

            {
              offset    : "00:10:00",
              skipoffset: 30,
              tag       : [
                "https://vebo-vast.pages.dev/overlay2.xml?v=1.0",
              ],
              type      : "nonlinear",
            },
          ],
          skipmessage     : "Có thể bỏ qua sau xx giây",
          vpaidcontrols   : true,
          vpaidmode       : "insecure",
        },
        logo           : logo,
      });*/

      $("#jw-container").show()

      // eslint-disable-next-line no-undef
      /*const peerInstance = new PEER2(player);
      this.$store.commit('match/setPlayer', player)
      this.$store.commit('match/setPeerInstance', peerInstance)
      if (this.enable_odds === true) {
        this.initOdds()
      }*/
    },
    requestFullscreen() {
      const elem = window.document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      }
    },
    showChat() {
      if (!this.show_frame_chat) {
        this.show_frame_chat = true
      }
      $(".mc_live").toggleClass("active");
      $("body").toggleClass("body-m-hidden");
      $("#toggle-chat").toggleClass("active");
      $("#count-height").toggleClass("m-height");
    },
    getChat() {
      let chat_url = null;
      if (window.chat_url_football && this.item.sport_type == 'football') {
        chat_url = window.chat_url_football;
      } else if (window.chat_url_basketball && this.item.sport_type == 'basketball') {
        chat_url = window.chat_url_basketball;
      }
      if (!chat_url) {
        if (window.default_chat_url) {
          chat_url = window.default_chat_url;
        } else {
          return 'https://www.youtube.com/live_chat?v=M5ekGVhjlW4&dark_theme=1&embed_domain=default';
        }
      }
      return `${chat_url}&embed_domain=${window.location.hostname}`;
    },
    playerFull() {
      $("body").toggleClass('player-full');
    }
  }
}
</script>