<template>
  <div class="com-block">
    <div class="com-header">
      <div class="heading"><i class="far fa-calendar-check mr-3"></i>LỊCH THI ĐẤU</div>
    </div>
    <div v-if="fixtures" >
      <div class="match_box" v-for="item in fixtures" :key="item.date">
        <div class="mb_-header">
          <strong>{{item.date_show}}</strong>
        </div>
        <div class="match_list match_list-list">
          <MatchFixtureItem v-for="(match, index) in item.data" :model="match" :key="index"></MatchFixtureItem>
        </div>
      </div>
    </div>
    <FixtureLoading v-else></FixtureLoading>
  </div>
</template>
<script>
import MatchFixtureItem from "@/components/tournament/fixture/MatchFixtureItem";
import FixtureLoading from "@/components/loading/FixtureLoading";
import {mapState, useStore} from "vuex";
//import {useRoute} from "vue-router/dist/vue-router";
export default {
  name: "TournamentFixture",
  props: [
    'model_id'
  ],
  components: {
    MatchFixtureItem,
    FixtureLoading,
  },
  watch:{
    model_id(value){
      if(value){
        this.$store.dispatch('tournament/fetchDateFixture',value)
      }
    }
  },

  computed: mapState({
    fixtures : state => state.tournament.fixtures,
  }),
  setup(props) {
    const store = useStore()
    store.dispatch('tournament/fetchDateFixture', props.model_id)

    setInterval(() => {
      store.dispatch('tournament/fetchDateFixture', props.model_id)
    }, 5 * 60 * 1000)

  }
}
</script>
