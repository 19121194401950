import * as moment from 'moment';

export const MATCH_STATUS = {
    PENDING: 'pending',
    LIVE: 'live',
    FINISHED: 'finished',
    CANCELED: 'canceled',
    POSTPONED: 'postponed',
    INTERRUPTED: 'interrupted',
    DELAY: 'delay',
}
export default class Match {
    name
    slug
    date
    timestamp
    home_red_cards
    away_red_cards
    home
    away
    tournament
    scores
    win_code
    match_status
    sport_type
    has_lineup
    has_tracker
    is_featured
    thumbnail_url
    commentators
    id
    time_str
    live_tracker

    venue
    referee
    play_urls
    is_live

    getId() {
        return this.id
    }

    constructor(obj) {
        Object.assign(this, obj)
    }

    tournamentDetailText() {
        let result = this.tournament.name
        return result
    }

    showTime() {
        return moment(this.timestamp).format('HH:mm')

    }

    showDate() {
        return moment(this.timestamp).format('DD/MM/YYYY')
    }

    showFullTime() {
        return moment(this.timestamp).format('DD/MM/YYYY HH:mm')
    }
    showDateText() {
        const date = moment(this.timestamp).startOf("day");
        const today = moment().startOf("day");
        let duration = moment.duration(date.diff(today));
        duration = duration.asDays();
        if (duration == -1) {
            return this.showTime() + ' ' + 'Hôm qua';
        } else if (duration == 0) {
            return this.showTime() + ' ' +  'Hôm nay';
        } else if (duration == 1) {
            return this.showTime() + ' ' + 'Ngày mai';
        } else {
            if(today.year() == date.year()){
                return this.showTime() + ' ' + (date.format("DD/MM"));
            }else{
                return this.showTime() + ' ' + (date.format("DD/MM/YYYY"));
            }
        }
    }
    getScore(team) {
        let result = '-'
        switch (this.match_status) {
            case 'pending':
            case 'canceled':
                result = '-'
                break
            default:
                if (team === 'home') {
                    result = `${this.scores.home}`
                } else {
                    result = `${this.scores.away}`
                }
        }
        return result
    }
    get home_score(){
        return this.getScore('home')
    }
    get away_score(){
        return this.getScore('away')
    }
    showHomeScore() {
        return this.getScore('home')
    }

    showAwayScore() {
        return this.getScore('away')
    }

    url() {
        return `match/${this.id}`
        // return `match/${this.slug}-${this.getId()}`
    }

    isEnd() {
        return this.match_status === 'finished'
    }

    isLive() {
        if (this.match_status === 'finished'){
            return false
        }else if (this.match_status === 'live') {
            return true
        } else if (this.match_status === 'pending' && this.play_urls && this.play_urls.length) {
            return true
        }else if (this.is_live === true){
            return true
        }
        return false
    }

    isCancel(){
        switch (this.match_status) {
            case MATCH_STATUS.CANCELED:
                return true
            default:
                return false
        }
    }


    get home_red_cards_arr() {
        let result = []
        for (let i = 0; i < this.home_red_cards; i++) {
            result.push(1)
        }
        return result
    }

    get away_red_cards_arr() {
        let result = []
        for (let i = 0; i < this.away_red_cards; i++) {
            result.push(i)
        }
        return result
    }
    casterName(){
        if(this.commentators && this.commentators.length > 0){
            let names = this.commentators.map(item => item.name)
            return names.join(' - ')
        }
        return ''
    }
}

