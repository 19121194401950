import {UserModel} from "@/store/models/user";
import ApiService from "@/services/ApiService";

export default {
    namespaced: true,
    state:{
        user: null,
    },
    mutations: {
        setUser(state, user) {
            if(user == null){
                state.user = null
            }else {
                state.user = new UserModel(user);
            }
            ApiService.setHeader()
        },

    },
    actions: {
        login({commit}, payload){
            commit('setUser', payload.user)
        },
        logout({commit}){
            commit('setUser', null)
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.user;
        },
        chatUrl(state) {
            if(state.user){
                return state.user.chat_url
            }
            if(window.default_chat_url){
                return window.default_chat_url
            }
            return "https://www6.cbox.ws/box/?boxid=827198&boxtag=wskql9"
        },
    },
}