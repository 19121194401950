<template>
  <div class="com-block">
    <div class="com-header">
      <div class="heading"><i class="fas fa-trophy mr-3"></i>BẢNG XẾP HẠNG</div>
    </div>
    <div class="box box-table">
      <div class="box-content">
        <div class="table-list table-list-medium">
          <div class="mb_-header">
            <strong>UEFA Champions League</strong>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group A</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2953/image" alt="Ajax" title="Ajax">
                    </div>
                    <strong>Ajax</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">4</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">4</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/44/image" alt="Liverpool" title="Liverpool">
                    </div>
                    <strong>Liverpool</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2714/image" alt="Napoli" title="Napoli">
                    </div>
                    <strong>Napoli</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2351/image" alt="Rangers" title="Rangers">
                    </div>
                    <strong>Rangers</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">4</div>
                <div class="tld tld-gd">-4</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group B</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2836/image" alt="Atlético Madrid" title="Atlético Madrid">
                    </div>
                    <strong>Atl. Madrid</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2681/image" alt="Bayer 04 Leverkusen" title="Bayer 04 Leverkusen">
                    </div>
                    <strong>Leverkusen</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2888/image" alt="Club Brugge" title="Club Brugge">
                    </div>
                    <strong>Club Brugge</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/3002/image" alt="FC Porto" title="FC Porto">
                    </div>
                    <strong>Porto</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group C</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2672/image" alt="Bayern München" title="Bayern München">
                    </div>
                    <strong>Bayern M.</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2817/image" alt="Barcelona" title="Barcelona">
                    </div>
                    <strong>Barcelona</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/4502/image" alt="Viktoria Plzeň" title="Viktoria Plzeň">
                    </div>
                    <strong>Viktoria Plzeň</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2697/image" alt="Inter" title="Inter">
                    </div>
                    <strong>Inter</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group D</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/3001/image" alt="Sporting CP" title="Sporting CP">
                    </div>
                    <strong>Sporting</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">3</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">3</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/33/image" alt="Tottenham" title="Tottenham">
                    </div>
                    <strong>Tottenham</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/1641/image" alt="Olympique de Marseille" title="Olympique de Marseille">
                    </div>
                    <strong>Marseille</strong>
                  </a>
                </div>
                <div class="tld tld-played">0</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2674/image" alt="Eintracht Frankfurt" title="Eintracht Frankfurt">
                    </div>
                    <strong>E. Frankfurt</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">3</div>
                <div class="tld tld-gd">-3</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group E</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2032/image" alt="GNK Dinamo Zagreb" title="GNK Dinamo Zagreb">
                    </div>
                    <strong>Dinamo</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">1</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">1</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2692/image" alt="Milan" title="Milan">
                    </div>
                    <strong>Milan</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">1</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">1</div>
                <div class="tld tld-ga">1</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">1</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-draw"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2046/image" alt="Red Bull Salzburg" title="Red Bull Salzburg">
                    </div>
                    <strong>RB Salzburg</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">1</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">1</div>
                <div class="tld tld-ga">1</div>
                <div class="tld tld-gd">0</div>
                <div class="tld tld-point"><span class="score">1</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-draw"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/38/image" alt="Chelsea" title="Chelsea">
                    </div>
                    <strong>Chelsea</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">1</div>
                <div class="tld tld-gd">-1</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group F</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/3313/image" alt="Shakhtar Donetsk" title="Shakhtar Donetsk">
                    </div>
                    <strong>Shakhtar D.</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">4</div>
                <div class="tld tld-ga">1</div>
                <div class="tld tld-gd">3</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2829/image" alt="Real Madrid" title="Real Madrid">
                    </div>
                    <strong>Real Madrid</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">3</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">3</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/36360/image" alt="RB Leipzig" title="RB Leipzig">
                    </div>
                    <strong>RB Leipzig</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">1</div>
                <div class="tld tld-ga">4</div>
                <div class="tld tld-gd">-3</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2352/image" alt="Celtic" title="Celtic">
                    </div>
                    <strong>Celtic</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">3</div>
                <div class="tld tld-gd">-3</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group G</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/17/image" alt="Manchester City" title="Manchester City">
                    </div>
                    <strong>Man City</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">4</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">4</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2673/image" alt="Borussia Dortmund" title="Borussia Dortmund">
                    </div>
                    <strong>Dortmund</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">3</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">3</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/1284/image" alt="FC København" title="FC København">
                    </div>
                    <strong>København</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">3</div>
                <div class="tld tld-gd">-3</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2833/image" alt="Sevilla" title="Sevilla">
                    </div>
                    <strong>Sevilla</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">4</div>
                <div class="tld tld-gd">-4</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="tl_-allclub">
            <div class="group-name">Group H</div>
            <div class="tl-row tl_a-header">
              <div class="tld tld-number">#</div>
              <div class="tld tld-name">Đội bóng</div>
              <div class="tld tld-played">ST</div>
              <div class="tld tld-win">T</div>
              <div class="tld tld-draw">H</div>
              <div class="tld tld-lose">B</div>
              <div class="tld tld-gf">TG</div>
              <div class="tld tld-ga">TH</div>
              <div class="tld tld-gd">HS</div>
              <div class="tld tld-point">Đ</div>
              <div class="tld tld-result">KQ</div>
            </div>
            <div class="tl-row-wrap">
              <div class="tl-row">
                <div class="tld tld-number">1</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/3006/image" alt="SL Benfica" title="SL Benfica">
                    </div>
                    <strong>Benfica</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">2</div>
                <div class="tld tld-ga">0</div>
                <div class="tld tld-gd">2</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">2</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/1644/image" alt="Paris Saint-Germain" title="Paris Saint-Germain">
                    </div>
                    <strong>PSG</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">1</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">0</div>
                <div class="tld tld-gf">2</div>
                <div class="tld tld-ga">1</div>
                <div class="tld tld-gd">1</div>
                <div class="tld tld-point"><span class="score">3</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-win"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">3</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/2687/image" alt="Juventus" title="Juventus">
                    </div>
                    <strong>Juventus</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">1</div>
                <div class="tld tld-ga">2</div>
                <div class="tld tld-gd">-1</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
              <div class="tl-row">
                <div class="tld tld-number">4</div>
                <div class="tld tld-name">
                  <a href="javascript:void(0)" title="">
                    <div class="team-logo icon-20 mr-2">
                      <img loading="lazy" class="team-logo-img" src="https://api.sofascore.com/api/v1/team/5197/image" alt="Maccabi Haifa" title="Maccabi Haifa">
                    </div>
                    <strong>M. Haifa</strong>
                  </a>
                </div>
                <div class="tld tld-played">1</div>
                <div class="tld tld-win">0</div>
                <div class="tld tld-draw">0</div>
                <div class="tld tld-lose">1</div>
                <div class="tld tld-gf">0</div>
                <div class="tld tld-ga">2</div>
                <div class="tld tld-gd">-2</div>
                <div class="tld tld-point"><span class="score">0</span></div>
                <div class="tld tld-result">
                  <span class="dot dot-lose"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>
<script>
//import {useRoute} from "vue-router/dist/vue-router";
export default {
  name: "TournamentStanding",
  setup(){
    /*const route = useRoute();
    console.log('params',route.params)
    return{
      slug : route.params.slug,
      modelId : route.params.id,
    }*/
  },
}
</script>
