<template>
  <div class="modal fade vbmodal vbmodal-login" id="modallogin" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="xclose" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></div>
        <div class="tab-content">
          <div id="modal-tab-login" class="tab-pane show active">
            <div class="modal-body">
              <div class="text-left">
                <h5 class="modal-title">Chào huynh đài,</h5>
                <div class="description">Huynh đài cần đăng nhập để tham gia bình luận và sử dụng các tính năng nâng cao nhé.</div>
              </div>
              <form id="form-login" class="vbform" @submit="submit">
                <div class="form-group">
                  <input v-model="email" type="email" required class="form-control" id="txt-email" placeholder="Email">
                </div>
                <div class="form-group">
                  <input v-model="password" type="password" required class="form-control" id="txt-pass" placeholder="Mật khẩu">
                </div>
                <div class="alert pre-alert alert-danger" id="block-err-msg" v-show="error_message">{{error_message}}</div>
                <div class="login-btn w-2btn">
                  <button class="btn vb-btn btn-primary btn-block" type="submit">
                    Đăng nhập
                    <span class="spinner-border spinner-border-sm" id="ld-login" v-show="loading"></span>
                  </button>
                  <a class="btn vb-btn btn-block btn-light" href="javascript:void(0)" @click="clickRegister" rel="nofollow">Đăng ký mới<i
                      class="fas fa-angle-right ml-2"></i></a>
                  <div class="clearfix"></div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from 'jquery'
import {ref} from "vue";
import UserService, {AUTH_DOMAIN} from "@/services/UserService";

export default {
  name: "LoginModal",
  data(){
    return {
      email: '',
      password: '',
      error_message: null,
    }
  },
  setup(){
    const loading = ref(false)
    return {
      loading
    }
  },
  methods:{
    async submit(e){
      e.preventDefault();
      this.error_message = null
      console.log({
        email: this.email,
        password: this.password,
      })
      this.loading = true
      try {
        const {
          user, access_token
        } = await UserService.login(this.email, this.password)
        if(user){
          try {
            $('#modallogin').modal('hide');
          }catch (e) {/**/}
          this.$store.dispatch('auth/login', {
            user,
          })
        }
        console.log('login-result', {
          user, access_token
        })
        return true
      }catch (e) {
        this.error_message = e.message
      }finally {
        this.loading = false
      }
      return false

    },
    clickRegister(){
      // window.location.href = `https://auth.vebotv.me/dang-ky?callback=${encodeURIComponent(window.location.href)}`;
      window.location.href = `${AUTH_DOMAIN}/dang-ky?callback=${encodeURIComponent(window.location.href)}`;
    }
  }
}
</script>

<style scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}
</style>