<template>
  <div class="item" v-for="(item,i) in data" :key="i">
    <custom-link :to="{ name: 'highlight_detail', params: { slug: item.slug, id: item.id } }" class="news-thumb">
      <img loading="lazy" :src="item.feature_image" class="news-thumb-img" :title="item.name" :alt="item.name"><div class="icon-chat"></div>
    </custom-link>
    <h4 class="news-title">
      <div class="highlight" v-if="item.category && item.category.name && show_cat">{{item.category.name}}</div>
      <custom-link :to="{ name: 'highlight_detail', params: { slug: item.slug, id: item.id } }">{{item.name}}</custom-link>
    </h4>
  </div>
</template>

<script>
export default {
  name: "HighlightList",
  props: [
      'data',
      'show_cat',
  ],

}
</script>