<template>
  <div v-if="deferredPrompt" class="vbnotice pos-bottom">
    <div class="container">
      <div class="alert show vbnotice_inner" role="alert">
        <div class="app-icon"><img v-bind:src="icon"></div>
        <div class="text">
          <p>{{ callOut }}</p>
          <p class="mb-0">
            <a @click="install" class="btn btn-radius btn-primary mr-1"><i class="fas fa-download mr-2"></i>Cài đặt</a>
            <a @click="dismiss" class="btn btn-radius btn-dark" style="background-color: #0065bb !important"
              data-dismiss="alert" aria-label="Close">Ẩn t.báo</a>
          </p>
        </div>
        <button @click="dismiss" type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";

export default {
  name: "InstallNotice",
  setup() {
    const app = getCurrentInstance()
    const icon = app.appContext.config.globalProperties.$icon;
    const callOut = `Cài đặt ngay ứng dụng ${window.domainName} để theo dõi các trận đấu một cách nhanh nhất với chất lượng cao nhất.`
    return { icon, callOut }
  },
  data() {
    return {
      deferredPrompt: null
    }
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Date.now() - localStorage.getItem("prompt-dismissed-time") > 10800000) {
        this.deferredPrompt = e;
      }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async dismiss() {
      localStorage.setItem("prompt-dismissed-time", Date.now());
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    }
  }
}
</script>