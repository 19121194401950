import { createApp } from 'vue'
import App from './App.vue'
import ApiService from "@/services/ApiService";
import store from "../src/store";
import router from "@/router";
import CustomLink from "@/libs/custom-link";
// import VueLazyLoad from 'vue3-lazyload'
// import '../public/register-service-worker'

// eslint-disable-next-line no-unused-vars
//import * as $ from 'jquery'
//window.$ = jquery
//import moment from "moment";

function makeid(length) {
	var result           = '';
	var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for ( var i = 0; i < length; i++ ) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const app                                  = createApp(App);
app.config.globalProperties.$siteId        = window.siteId;
app.config.globalProperties.$themeId       = window.themeId;
app.config.globalProperties.$siteName      = window.siteName;
app.config.globalProperties.$domainName    = window.domainName;
app.config.globalProperties.$logo          = window.logo;
app.config.globalProperties.$icon          = window.icon;
app.config.globalProperties.$banner        = window.banner;
app.config.globalProperties.$ads_color_top = window.ads_color_top;
app.config.globalProperties.$adsClass      = '_' + makeid(6);

ApiService.init(app);
app.use(store);
app.use(router);
app.use(CustomLink);
//app.use(VueLazyLoad);

app.mixin({
	methods: {
		getBtnAdsBet(){
			let bet_btn = null
			if (this.$route.name === 'match') {
				if(this.$store.state.asd.live){
					bet_btn = this.$store.state.asd.live.btn
				}
			}else{
				if(this.$store.state.asd.home){
					bet_btn = this.$store.state.asd.home.btn
				}
			}
			if(bet_btn && bet_btn.bet){
				return '/goto?url=' + encodeURIComponent(bet_btn.bet)
			}
			return '#'
		},
		getBtnAdsBetHome(){
			let bet_btn = null
			if(this.$store.state.asd.home){
				bet_btn = this.$store.state.asd.home.btn
			}
			if(bet_btn && bet_btn.home_match){
				return '/goto?url=' + encodeURIComponent(bet_btn.home_match)
			}
			return '#'
		},
		getBtnAdsBetLive(id = 'live_match') {
			let bet_btn = null
			if (this.$store.state.asd.live) {
				bet_btn = this.$store.state.asd.live.btn
			}
			if (bet_btn && bet_btn[id]) {
				return '/goto?url=' + encodeURIComponent(bet_btn[id])
			} else if (bet_btn && bet_btn.live_match) {
				return '/goto?url=' + encodeURIComponent(bet_btn.live_match)
			}
			return '#'
		},

		getBtnAdsBetLiveO(){
			let bet_btn = null
			if(this.$store.state.asd.live){
				bet_btn = this.$store.state.asd.live.btn
			}
			if(bet_btn && bet_btn.live_match){
				return '/goto?url=' + encodeURIComponent(bet_btn.live_match)
			}
			return '#'
		},
		getMeta: async function (route_name, param = null) {
			let url = `/api/meta/${window.location.hostname}/${route_name}`;
			if(param){
				url += `/${param}`;
			}
			await ApiService.get(url).then((res) => {
				if (res && res.data && res.data.data) {
					let data = res.data.data;
					document.title = data.title;
					if (document.querySelector('meta[name="description"]') && data.description) {
						document.querySelector('meta[name="description"]').setAttribute("content", data.description);
					}
					if (document.querySelector('meta[name="keywords"]') && data.description) {
						document.querySelector('meta[name="keywords"]').setAttribute("content", data.keywords);
					}
					if (document.querySelector('meta[property="og:title"]') && data.title) {
						document.querySelector('meta[property="og:title"]').setAttribute("content", data.title);
					}
					if (document.querySelector('meta[property="og:description"]') && data.description) {
						document.querySelector('meta[property="og:description"]').setAttribute("content", data.description);
					}
					if (document.querySelector('meta[property="og:image"]') && data.og_image) {
						document.querySelector('meta[property="og:image"]').setAttribute("content", data.og_image);
					}
					if (document.querySelector('h1') && data.h1) {
						document.querySelector('h1').innerHTML = data.h1;
					}
					if (document.querySelector('#content_top') && data.content_top) {
						document.querySelector('#content_top').innerHTML = data.content_top;
					}
					if (document.querySelector('#content_bot') && data.content_bot) {
						document.querySelector('#content_bot').innerHTML = data.content_bot;
					}
				}
			})
		},
	},
})

app.mount('#app')

