<template>
  <div id="footer">
    <div class="container">
      <div class="footer-copyright">
        <div class="footer-logo">
          <img loading="lazy" :src="this.$logo">
          <div class="clearfix"></div>
        </div>
        <div class="copyright">Copyright © {{(new Date()).getFullYear()}} by {{this.$domainName}}</div>
      </div>
      <div class="footer_menu">
        <div class="item">
          <ul class="no-bullets">
            <li><a href="javascript:;" title="">Về chúng tôi</a></li>
            <li><a href="javascript:;" title="">Chính sách</a></li>
            <li><a href="javascript:;" title="">Điều khoản</a></li>
          </ul>
        </div>
        <div class="item">
          <ul class="no-bullets">
            <li><a href="javascript:;" title="">Tin tức</a></li>
            <li><a href="javascript:;" title="">Liên hệ</a></li>
            <li><a href="javascript:;" title="">Thành viên</a></li>
          </ul>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="footer_about">
        <div class="description">
          Mọi trận đấu cho dù giải nhỏ cho tới các giải đấu lớn thì {{this.$domainName}} đều cung cấp đầy đủ link xem trực tiếp bóng đá online với độ phân giải và chất lượng cao nhất. Ngoài việc xem bóng đá trực tuyến, chúng tôi còn gửi tới bạn đọc lịch thi đấu bóng đá, kết quả bóng đá và soi kèo bóng đá với tỷ lệ chiến thắng cao. Chúc bạn đọc xem bóng đá vui vẻ và luôn ủng hộ {{this.$domainName}}
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped>

</style>