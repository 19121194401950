<template :key="route_name">
  <!--Begin: Header-->
  <div id="header" class="header-home">
    <div class="container">
      <div class="for-mobile">
        <div class="m-btn" id="m-menu"><i class="fas fa-bars"></i></div>
        <div class="clearfix"></div>
      </div>
      <custom-link :to="{ name: 'home' }" id="logo">
        <img loading="lazy" :src="this.$logo" :title="this.$domainName" :alt="this.$domainName">
        <span style="color: white; justify-content: center;">
          <i v-if="swReady" class='fas fa-check' style="margin-left: 0.75em;"></i>
          <i v-else class="fas fa-spinner fa-pulse" style="margin-left: 0.75em;"></i>
        </span>
        <div class="clearfix"></div>
      </custom-link>
      <div id="header-menu">
        <ul class="no-bullets">
          <li :class="(this.$route.name === 'home' ? 'active' : '')">
            <custom-link :to="{ name: 'home' }" title="Xem trực tiếp bóng đá miễn phí nhanh nhất">Trực Tiếp</custom-link>
          </li>
          <li :class="(['league_overview', 'league_schedule', 'league_result', 'league_standing'].includes(this.$route.name) ? 'active' : '')"
            class="submenu">
            <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="">
              Giải Đấu <i class="fas fa-angle-down ml-2"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-vb dropdown-menu-league dml-xcol">
              <custom-link v-for="(item, index) in menu_tournament" :key="index"
                :to="{ name: 'league_overview', params: { slug: item.slug, id: item.unique_ids.default } }"
                class="dropdown-item">
                <div class="item-league">
                  <i class="league-icon"><img loading="lazy" :src="item.logo" :title="item.name" :alt="item.name"></i>
                  <span>{{ item.name }}</span>
                </div>
              </custom-link>
            </div>
          </li>
          <li :class="(['result', 'result_day'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'result_day', params: { slug: 'hom-nay' } }" title="Kết quả bóng đá mới nhất">Kết quả</custom-link>
          </li>
          <li :class="(['fixture', 'fixture_day'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'fixture_day', params: { slug: 'hom-nay' } }" title="lịch trực tiếp bóng đá cập nhật nhanh nhất">Lịch Thi Đấu</custom-link>
          </li>
          <li :class="(['news', 'news_page', 'news_detail'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'news' }" title="Tin tức, nhận định soi kèo bóng đá hôm nay">Tin tức</custom-link>
          </li>
          <li :class="(['highlight', 'highlight_page', 'highlight_detail'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'highlight' }" title="Video highlight các trận đấu đỉnh cao">Highlights</custom-link>
          </li>
          <li :class="(['soikeo', 'soikeo_page', 'soikeo_detail'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'soikeo' }" title="Soi kèo bóng đá hôm nay">Soi kèo</custom-link>
          </li>
          <li :class="(['xemlai', 'xemlai_page', 'xemlai_detail'].includes(this.$route.name) ? 'active' : '')">
            <custom-link :to="{ name: 'xemlai' }" title="Xem lại bóng đá đỉnh cao">Xem lại</custom-link>
          </li>
        </ul>
      </div>
      <div class="nav-right">
        <a :href="getBtnAdsBet()" target="_blank" rel="nofollow" class="r-btn" id="m-bet"><i class="fas fa-futbol mr-2"></i><span>Đặt Cược</span></a>
        <!--<a data-toggle="modal" data-target="#modalapp" class="r-btn" id="m-app"><i class="fas fa-arrow-circle-down"></i><span>Tải App</span></a>-->
        <div id="user-display">
          <div class="dropdown" v-if="isLoggedIn">
            <button type="button" id="for-member" class="btn-avatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="logged-avatar" loading="lazy" :src="user.avatar" :alt="user.name" :title="user.name">
            </button>
            <div id="user_menu" class="dropdown-menu dropdown-menu-model">
              <custom-link :to="{ name: 'profile' }" class="dropdown-item"><i class="fas fa-user mr-2"></i>Tài khoản</custom-link>
              <a @click="logOut" class="dropdown-item di-bottom" href="javascript:void(0)"><span class="text-danger">Đăng xuất</span></a>
            </div>
          </div>
          <a v-else id="for-guest" data-toggle="modal" data-target="#modallogin" class="r-btn btn-user"><i class="fas fa-user-alt"></i></a>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  <!--End: Header-->

  <!--Begin: Sidebar-->
  <div id="sidebar-menu">
    <div class="sm-content">
      <div class="sb-logo">
        <custom-link :to="{ name: 'home' }">
          <img loading="lazy" :src="this.$logo" :title="this.$domainName" :alt="this.$domainName">
        </custom-link>
      </div>
      <div id="sb-search">
        <div class="search-content">
          <form action="search()">
            <div class="search-icon"><i class="fas fa-search"></i></div>
            <input type="text" class="form-control search-input" placeholder="Tìm kiếm...">
          </form>
        </div>
      </div>
      <div class="item">
        <div class="item-title">Chuyên mục</div>
        <ul class="no-bullets">
          <li><custom-link class="sb-link" :to="{ name: 'home' }" title="Xem trực tiếp bóng đá miễn phí nhanh nhất">Trực
              Tiếp</custom-link></li>
          <li>
            <a class="sb-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="">
              Giải Đấu <i class="fas fa-angle-down ml-1"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-vb dropdown-menu-league dml-xcol dm-relative">
              <custom-link v-for="(item, index) in menu_tournament" :key="index"
                :to="{ name: 'league_overview', params: { slug: item.slug, id: item.unique_ids.default } }"
                class="dropdown-item">
                <div class="item-league">
                  <i class="league-icon"><img loading="lazy" :src="item.logo" :title="item.name" :alt="item.name"></i>
                  <span>{{ item.name }}</span>
                </div>
              </custom-link>
            </div>
          </li>
          <li><custom-link class="sb-link" :to="{ name: 'result_day', params: { slug: 'hom-nay' } }" title="Kết quả bóng đá mới nhất">Kết quả</custom-link></li>
          <li><custom-link class="sb-link" :to="{ name: 'fixture_day', params: { slug: 'hom-nay' } }" title="lịch trực tiếp bóng đá cập nhật nhanh nhất">Lịch Thi Đấu</custom-link></li>
          <li><custom-link class="sb-link" :to="{ name: 'news' }" title="Tin tức, nhận định soi kèo bóng đá hôm nay">Tin tức</custom-link></li>
          <li><custom-link class="sb-link" :to="{ name: 'highlight' }" title="Video highlight các trận đấu đỉnh cao">Highlights</custom-link></li>
          <li><custom-link class="sb-link" :to="{ name: 'soikeo' }" title="Soi kèo bóng đá hôm nay">Soi kèo</custom-link></li>
          <li><custom-link class="sb-link" :to="{ name: 'xemlai' }" title="Xem lại những trận bóng tâm điểm">Xem lại</custom-link></li>
        </ul>
        <div class="clearfix"></div>
      </div>
      <div class="item">
        <div class="item-title">{{ this.$domainName }}</div>
        <ul class="no-bullets">
          <li><a class="sb-link" href="javascript:;" title="">Về chúng tôi</a></li>
          <li><a class="sb-link" href="javascript:;" title="">Chính sách</a></li>
          <li><a class="sb-link" href="javascript:;" title="">Điều khoản</a></li>
          <li><a class="sb-link" href="javascript:;" title="">Liên hệ</a></li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <div id="sidebar-menu-mask" style="display: none;"></div>
  <!--End: Sidebar-->
</template>

<script>
import { mapState, mapGetters, useStore } from "vuex";
import UserService from "@/services/UserService";
//import {useRoute} from "vue-router/dist/vue-router";
export default {
  name: "PageHeader",
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      user: state => state.auth.user,
      menu_tournament: state => state.tournament.menu,
    }),
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn"
    }),
  },
  data() {
    return { route_name: this.$route.name, swReady: false }
  },
  created() {
    document.addEventListener('swReady', () => { this.swReady = true }, { once: true })
  },
  setup() {
    if(!['/goto','/'].includes(window.location.pathname)){
      const store = useStore()
      if (!store.state.tournament.menu || store.state.tournament.menu.length === 0) {
        store.dispatch('tournament/fetchMenu')
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      UserService.logout()
    },
  },
}
</script>
