<template>
  <div id="main-wrapper">
    <div class="container">
      <p>Đang làm mới dữ liệu ứng dụng...</p>
      <p v-if="!loading && err.length === 0">Đã làm mới dữ liệu ứng dụng thành công</p>
      <p v-if="!loading && err.length > 0" style="color: red; font-weight: bold;">Đã có lỗi xảy ra: {{ err }}</p>
      <a v-if="!loading" :href="homeUrl"><button type="button" class="btn btn-primary rounded">Quay lại trang
          chủ</button></a>
    </div>
  </div>
</template>

<script>
import * as localforage from "localforage";

export default {
  name: "FixPage",
  data() {
    return {
      loading: true,
      err: ''
    }
  },
  computed: {
    homeUrl() {
      const url = new URL(window.location.origin)
      return url.toString()
    }
  },
  methods: {
    setLoading(loading) {
      this.loading = loading
    },
    setError(err) {
      this.loading = false
      this.err = err
    }
  },
  mounted() {
    navigator.serviceWorker.controller.postMessage({ type: "GET_CLIENTS" });
    navigator.serviceWorker.addEventListener('message', event => {
      console.log(event.data)
      if (event.data && event.data.type === 'RECEIVE_CLIENTS') {
        if (event.data.payload > 1) {
          this.setError('Có nhiều hơn 1 tab đang mở, vui lòng đóng các tab khác')
          return
        } else {
          fetch(`${window.location.origin}/service-worker.json`).then((response) => {
            if (!response.ok || response.status !== 200) {
              this.setError('Không kết nối được với máy chủ, vui lòng bật 1.1.1.1')
              return
            } else {
              navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                  registration.unregister()
                }
              })
              localforage.dropInstance()
              localStorage.clear();
              caches.delete(`90-cache-runtime-${self.location.origin}/`)
              caches.delete(`90-cache-precache-v2-${self.location.origin}/`)
              this.setLoading(false)
            }
          })
        }
      }
    });
  }
}
</script>