<template>
  <div class="box box-list mb-0">
    <div class="box-header">
      <h2 class="headlineA1">Lịch Trực Tiếp</h2>
    </div>
    <div class="box-content position-relative">
      <div id="date-overlay"></div>
      <div class="match_date match_date_home">
        <div class="md_-live">
          <a class="btn btn-md btn-live" :class="{active : 'live' === active_date}"
             @click="()=>{ this.active_date = 'live'}"
             v-if="this.live">LIVE<span class="badg">{{ this.live.length }}</span></a></div>
        <div class="mdx_-list">
          <a href="javascript:void(0)" class="item f-date"
             :class="{active : date.value === active_date}"
             v-for="date in dates" :key="date.value" @click="()=>{ this.active_date = date.value}">
            <span class="date-a">{{ date.day }}</span><span class="date-b">{{ date.sub }}</span>
          </a>
        </div>
      </div>
      <div v-show="active_date=== 'live'">
        <FixtureDate :date="'live'" :key="'live'"></FixtureDate>
      </div>
      <div v-for="date in dates" :key="date.value" v-show="date.value === active_date">
        <FixtureDate :date="date.value" :key="date.value"></FixtureDate>
      </div>

      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import FixtureDate from "@/components/home/fixture/FixtureDate";
import {mapState} from "vuex";

const dates = []
for (let i = -1; i < 5; i++) {
  const dateTime = moment().add(i, 'days')
  let day = dateTime.format('DD')
  const value = dateTime.format('YYYYMMDD')
  let sub = dateTime.format('dddd')
  if (i === 0) {
    sub = 'Hôm nay'
  }
  dates.push({
    day: day,
    sub: sub,
    value: value,
    time: dateTime
  })
}
export default {
  name: "FixtureList",
  components: {
    FixtureDate
  },
  data() {
    return {
      dates,
      active_date: null,
    }
  },
  watch: {
    // whenever question changes, this function will run
    active_date(value, old) {
      if (value !== 'live' && value !== old) {
        if (!this.date_matches[value]) {
          const store = this.$store
          setTimeout(function () {
            store.commit('match/setDateFixture', {
              date: value,
              data: null
            })
            store.dispatch('match/fetchHomeDateFixtures', value)
          }, 0)
        }
      }
    }
  },
  computed: {
    ...mapState({
      // arrow functions can make the code very succinct!
      live: state => state.match.live,
      date_matches: state => state.match.home_date_matches,
    }),
  },
  mounted() {
    this.active_date = moment().format('YYYYMMDD')
  }
}
</script>

<style scoped>

</style>