<template>
  <AdsRow1></AdsRow1>
  <div id="main-wrapper">
    <div class="container">
      <div class="box box-news">
        <div class="box-header">
          <h1 class="headlineA1">Xem lại những trận đấu đỉnh cao nhất trên 90Phut TV</h1>
          <div id="content_top"></div>
        </div>
        <div class="box-content">
          <div class="mw-w-sidebar is-basic">
            <div class="main-left">
              <div class="news-big" v-if="highlight">
                <router-link :to="{ name: 'xemlai_detail', params: { slug: highlight.slug, id: highlight.id } }" class="news-thumb">
                  <img :src="highlight.feature_image" class="news-thumb-img"><div class="icon-chat"></div>
                </router-link>
                <div class="news-detail">
                  <h4 class="news-title">
                    <router-link :to="{ name: 'xemlai_detail', params: { slug: highlight.slug, id: highlight.id } }">{{highlight.name}}</router-link>
                  </h4>
                  <div class="description">{{highlight.description}}</div>
                </div>
              </div>

              <div class="news-list news-list-page">
                <XemlaiGrid :data="list" :show_cat="false"></XemlaiGrid>
                <div class="clearfix"></div>
              </div>
              <PagingBlock :currentPage="page" :routeName="'xemlai'" :routeNamePage="'xemlai_page'" :limit="limit" :total="total"></PagingBlock>
            </div>
            <div class="sidebar-right">
              <BetHostSmall></BetHostSmall>
              <AdsTable1></AdsTable1>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div id="content_bot" style="text-align: center; margin: 10px 0 30px"></div>
      </div>

    </div>
  </div>

</template>
<script>

import AdsRow1 from "@/components/ads/AdsRow1";
import BetHostSmall from "@/components/ads/BetHostSmall";
import AdsTable1 from "@/components/ads/AdsTable1";
import PagingBlock from "@/components/block/PagingBlock";
import ApiService from "@/services/ApiService";
import XemlaiGrid from "@/components/news/XemlaiGrid";

export default {
  name      : "XemlaiPage",
  components: {
    AdsRow1,
    BetHostSmall,
    AdsTable1,
    PagingBlock,
    XemlaiGrid,
  },
  data() {
    return {
      page     : this.$route.params.page || 1,
      limit    : 0,
      total    : 0,
      highlight: null,
      list     : [],
    }
  },

  async mounted() {
    const [
      response,
      // eslint-disable-next-line no-unused-vars
      meta,
    ] = await Promise.all([
      ApiService.get(`/api/news/${this.$siteId}/list/xemlai/${this.$route.params.page || 1}`),
      this.getMeta('xemlai', `${this.$route.params.page || 1}`)
    ])

    if (response && response.data && response.data.data) {
      this.list      = response.data.data.list
      this.highlight = response.data.data.highlight
      this.limit     = response.data.data.limit
      this.total     = response.data.data.total
    }
  },
  watch: {
    $route(route_to) {
      this.page = route_to.params.page
    },
    async page(value) {
      if (value) {
        const response = await ApiService.get(`/api/news/${this.$siteId}/list/xemlai/${value || 1}`)
        if (response && response.data && response.data.data) {
          this.list      = response.data.data.list
          this.highlight = response.data.data.highlight
          this.limit     = response.data.data.limit
          this.total     = response.data.data.total
        }
      }
    }
  },
}
</script>
