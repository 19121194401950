<template>
  <div id="xmenu">
    <div class="container" v-if="this.$themeId === 'vebotv'">
      <div class="xm-content">
        <h1 class="headline"><span class="highlight">{{this.$domainName}}</span> : Xem <span class="highlight">Trực Tiếp Bóng Đá</span> Miễn Phí Nhanh Nhất</h1>
        <div id="content_top" class="description"><p>{{this.$domainName}} là kênh cập nhật link xem trực tiếp bóng đá cho Fan hâm mộ Việt Nam và Quốc tế qua kết nối Internet. Xem bóng đá trực tuyến với trên Vebo TV đường truyền tốc độ cao, không lag giật tất cả các trận đấu lớn nhỏ trên toàn cầu.</p></div>
      </div>
      <div class="xm-img"><img loading="lazy" :src="this.$banner" alt="Link xem bóng đá trực tuyến miễn phí" title="Link xem bóng đá trực tuyến miễn phí"></div>
    </div>
    <div class="container" v-else-if="this.$themeId === 'banhkhuc'">
      <div class="xm-content">
        <h1 class="headline"><span class="highlight">{{this.$domainName}}</span> Xem trực tiếp bóng đá online bình luận tiếng Việt</h1>
        <div id="content_top" class="description"><p>{{this.$domainName}} là kênh cập nhật link xem trực tiếp bóng đá cho Fan hâm mộ Việt Nam và Quốc tế qua kết nối Internet. Xem bóng đá trực tuyến với đường truyền tốc độ cao, không lag giật tất cả các trận đấu lớn nhỏ trên toàn cầu.</p></div>
      </div>
      <div class="xm-img"><img loading="lazy" :src="this.$banner" alt="Link xem bóng đá trực tuyến miễn phí" title="Link xem bóng đá trực tuyến miễn phí"></div>
    </div>
    <div class="container" v-else-if="this.$themeId === 'xoilac'">
      <div class="xm-content">
        <h1 class="headline"><span class="highlight">{{this.$domainName}}</span> - Kênh xem bóng đá online nhanh nhất</h1>
        <div id="content_top" class="description"><p>Trang {{this.$domainName}}: Website chính thức xoilac.tv xem trực tiếp bóng đá với link tốc độ cao bình luận tiếng Việt hay nhất</p></div>
      </div>
      <div class="xm-img"><img loading="lazy" :src="this.$banner" alt="Link xem bóng đá trực tuyến miễn phí" title="Link xem bóng đá trực tuyến miễn phí"></div>
    </div>
    <div class="container" v-else-if="this.$themeId === '90phut' || this.$themeId === '90p'">
      <div class="xm-content">
        <h1 class="headline"><span class="highlight">{{this.$domainName}}</span> - Kênh xem trực tiếp bóng đá online nhanh nhất</h1>
        <div id="content_top" class="description"><p>Website {{this.$domainName}}: Đây là trang xem trực tiếp bóng đá với link tốc độ cao bình luận tiếng Việt hay nhất</p></div>
      </div>
      <div class="xm-img"><img loading="lazy" :src="this.$banner" alt="Link xem bóng đá trực tuyến miễn phí" title="Link xem bóng đá trực tuyến miễn phí"></div>
    </div>
    <div class="container" v-else>
      <div class="xm-content">
        <h1 class="headline"><span class="highlight">{{this.$domainName}}</span> : Xem <span class="highlight">Trực Tiếp Bóng Đá</span> Miễn Phí Nhanh Nhất</h1>
        <div id="content_top" class="description"><p>{{this.$domainName}} là kênh cập nhật link xem trực tiếp bóng đá cho Fan hâm mộ Việt Nam và Quốc tế qua kết nối Internet. Xem bóng đá trực tuyến với trên Vebo TV đường truyền tốc độ cao, không lag giật tất cả các trận đấu lớn nhỏ trên toàn cầu.</p></div>
      </div>
      <div class="xm-img"><img loading="lazy" :src="this.$banner" alt="Link xem bóng đá trực tuyến miễn phí" title="Link xem bóng đá trực tuyến miễn phí"></div>
    </div>
  </div>
  <AdsRow1></AdsRow1>
  <MatchFeaturedList></MatchFeaturedList>
  <AdsRow2></AdsRow2>
  <div id="main-wrapper">
    <div class="container">
      <div class="mw-w-sidebar">
        <div class="main-left">
          <FixtureList></FixtureList>
        </div>
        <div class="sidebar-right">
          <BetHostBig></BetHostBig>
          <AdsRow3></AdsRow3>
          <StandingTable></StandingTable>
          <AdsTable1></AdsTable1>
          <!--<div class="box box-highlight">
            <div class="box-header">
              <h2 class="headlineA1"><a href="highlight.html" title="">Hightlight</a></h2>
            </div>
            <div class="box-content">
              <div class="video-list">
                <div class="item">
                  <a href="video.html" class="video-thumb"><img src="/bk/images/thumbs/video-01.jpg" class="video-thumb-img"></a>
                  <h4 class="video-title"><a href="video.html" title="">Highlight trận đấu Ukraine vs England ngày 04/07</a></h4>
                </div>
                <div class="item">
                  <a href="video.html" class="video-thumb"><img src="/bk/images/thumbs/video-02.jpg" class="video-thumb-img"></a>
                  <h4 class="video-title"><a href="video.html" title="">Highlight trận đấu Ukraine vs England ngày 04/07</a></h4>
                </div>
                <div class="item">
                  <a href="video.html" class="video-thumb"><img src="/bk/images/thumbs/video-03.jpg" class="video-thumb-img"></a>
                  <h4 class="video-title"><a href="video.html" title="">Highlight trận đấu Ukraine vs England ngày 04/07</a></h4>
                </div>
                <div class="item">
                  <a href="video.html" class="video-thumb"><img src="/bk/images/thumbs/video-04.jpg" class="video-thumb-img"></a>
                  <h4 class="video-title"><a href="video.html" title="">Highlight trận đấu Ukraine vs England ngày 04/07</a></h4>
                </div>
                <div class="clearfix"></div>
              </div>
              <a href="highlight.html" class="load-more btn-blank"><i class="fas fa-angle-down mr-2"></i>Xem thêm</a>
              <div class="clearfix"></div>
            </div>
          </div>-->
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <AdsRow4></AdsRow4>
  <NewsBlock></NewsBlock>
  <div id="content_bot" style="text-align: center; margin: 10px 0 30px"></div>
</template>

<script>
import MatchFeaturedList from "@/components/home/featured/MatchFeaturedList";
import FixtureList from "@/components/home/fixture/FixtureList";
import StandingTable from "@/components/home/standing/StandingTable";
import NewsBlock from "@/components/news/HomeBlock";

import AdsRow1 from "@/components/ads/AdsRow1";
import AdsRow2 from "@/components/ads/AdsRow2";
import AdsRow4 from "@/components/ads/AdsRow4";
import AdsRow3 from "@/components/ads/AdsRow3";
import AdsTable1 from "@/components/ads/AdsTable1";
import BetHostBig from "@/components/ads/BetHostBig";
export default {
  name: "HomePage",
  components: {
    MatchFeaturedList,
    FixtureList,
    StandingTable,
    NewsBlock,

    AdsRow1,
    AdsRow2,
    AdsRow3,
    AdsRow4,
    AdsTable1,
    BetHostBig,
  },
  async mounted() {
    await this.getMeta(this.$route.name)
  },
}
</script>