<template :key="key">
  <AdsRow1></AdsRow1>
  <div id="main-wrapper">
    <div class="container">
      <div class="box box-news box-news-detail">
        <div class="box-header">
          <h1 class="headlineA1" v-if="news && news.name">{{news.name}}</h1>
          <div id="content_top"></div>
        </div>
        <div class="box-content">
          <div class="mw-w-sidebar is-basic">
            <div class="main-left">
              <div class="read-news box-wpd box-wbg">
                <div class="row">
                  <div class="col-6 an-social mb-3">
                    <div class="social-block">
                      <div class="addthis_inline_share_toolbox"></div>
                      <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="col-6">
                    <div style="text-align: right;padding: 5px 0;font-style: italic;font-size: 0.85rem;" v-if="news_created">{{news_created}}</div>
                  </div>
                </div>
                <div id="player_news"></div>
                <div class="article-news">
                  <div v-if="news && news.content" v-html="news.content"></div>
                  <div v-if="news && news.author" class="author-box">
                    <div class="block-inner">
                      <custom-link :to="{ name: 'author_detail', params: { slug: news.author.slug } }" class="author-photo" :title="news.author.name">
                        <img :src="news.author.img" width="500" height="500" :alt="news.author.name" class="avatar">
                      </custom-link>
                      <div class="author-info">
                        <custom-link :to="{ name: 'author_detail', params: { slug: news.author.slug } }" class="author-name">{{news.author.name}}</custom-link>
                        <div class="author-descr">{{news.author.excerpt}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="box-wbg" style="margin: 0;padding: 20px 0 0;" v-if="data_related">
                <div class="container">
                  <div class="box box-review mb-0">
                    <div class="box-header">
                      <h2 class="headlineA1"><a href="/highlight">Những pha highlight HOT</a></h2>
                    </div>
                    <div class="box-content">
                      <div class="news-list">
                        <HighlightGrid :data="data_related" :show_cat="true"></HighlightGrid>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-right">
              <AdsTable1></AdsTable1>
              <div class="box box-news box-news-sidebar" v-if="data_hot">
                <div class="box-header">
                  <h1 class="headlineA1">Nững pha highlight nổi bật</h1>
                </div>
                <div class="box-content">
                  <div class="news-list">
                    <HighlightList :data="data_hot" :show_cat="true"></HighlightList>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <BetHostSmall></BetHostSmall>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div id="content_bot" style="text-align: center; margin: 10px 0 30px"></div>
      </div>

    </div>
  </div>

</template>
<script>

import moment from "moment";
import AdsRow1 from "@/components/ads/AdsRow1";
import BetHostSmall from "@/components/ads/BetHostSmall";
import AdsTable1 from "@/components/ads/AdsTable1";
import ApiService from "@/services/ApiService";
import HighlightGrid from "@/components/news/HighlightGrid";
import HighlightList from "@/components/news/HighlightList";

export default {
  name      : "HighlightDetailPage",
  components: {
    AdsRow1,
    AdsTable1,
    BetHostSmall,
    HighlightGrid,
    HighlightList,
  },
  data() {
    return {
      key         : 'detail_' + this.$route.params.id,
      news        : null,
      news_created: null,
      data_related: [],
      data_hot    : [],
    }
  },

  async mounted() {
    // eslint-disable-next-line no-undef
    jwplayer.key = '3SYLbRo6MN5cBDxwpZh3dl1gb0lMTUOos31M5hoAlf4=';
    const [
      response,
      // eslint-disable-next-line no-unused-vars
      meta,
    ] = await Promise.all([
      ApiService.get(`/api/news/${this.$siteId}/detail/${this.$route.params.id}`),
      this.getMeta('news_detail', `${this.$route.params.id}`),
    ])
    if (response && response.data && response.data.data) {
      this.news         = response.data.data
      this.data_related = response.data.data_related
      this.data_hot     = response.data.data_hot
      this.news_created = moment(response.data.data.created_at).format('HH:mm [ngày] DD/MM/YYYY')

      if(response.data.data.video_url){
        this.setupPlayer(response.data.data.video_url);
      }
    }
  },
  watch: {
    $route(route_to) {
      this.key = 'detail_' + route_to.params.id
    },
    async key(value) {
      if (value) {
        const response = await ApiService.get(`/api/news/${this.$siteId}/detail/${this.$route.params.id || 1}`)
        if (response && response.data && response.data.data) {
          this.news         = response.data.data
          this.data_related = response.data.data_related
          this.data_hot     = response.data.data_hot
          this.news_created = moment(response.data.data.created_at).format('HH:mm [ngày] DD/MM/YYYY')

          if(response.data.data.video_url){
            this.setupPlayer(response.data.data.video_url);
          }
        }
      }
    }
  },
  methods: {
    setupPlayer(videoUrl) {
      let logo = {
        file    : "https://vebo-vast.pages.dev/f88-logo.gif?v=1.0",
        hide    : false,
        link    : "https://www.fb88affok.com/CreativeBanner/Track?aID=5456&cID=1060&tID=2272",
        margin  : "6",
        position: "bottom-right",
      }

      // eslint-disable-next-line no-undef
      jwplayer('player_news').setup({
        file           : videoUrl,
        preload        : "auto",
        primary        : "html5",
        withCredentials: false,
        logo           : logo,
        controls: true,
        responsive: true,
        width: "100%",
        aspectratio: "16:9"
        /*skin: {
          name: "netflix"
        },*/
        //title          : `${title}`,
      });
    },
  }
}
</script>
